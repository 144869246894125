var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.randomizedOptions)?_c('div',{staticClass:"full-area",class:{'feedback': _vm.feedback}},[_c('FeedbackScreen',{attrs:{"data":_vm.data,"feedback":_vm.feedback},on:{"retry":function($event){return _vm.retry()},"next":function($event){return _vm.clearAndGo()}}}),_c('QuestionHeader',{attrs:{"data":_vm.data}}),(_vm.data.fields.questionSubtitle && _vm.randomizedOptions.length <= 4)?_c('h2',{staticClass:"optional-question-title",class:{'question-subtitle-strong': _vm.data.fields.questionImage}},[_vm._v(_vm._s(_vm.data.fields.questionSubtitle))]):_vm._e(),_c('content',{staticClass:"content",class:{
      'align-top': _vm.randomizedOptions.length >= 4,
      'three-rows': _vm.randomizedOptions.length > 4
    }},[_c('div',{staticClass:"grid-wrapper delay-entry"},_vm._l((_vm.randomizedOptions),function(box,key){return _c('div',{key:box.sys.id,staticClass:"binary-box",class:{
          'standoutx': _vm.form.selected === box.fields.title || _vm.form.selected.includes(box.fields.title),
          'render-image-wrapper': _vm.data.fields.renderImageTitle,
          'xtra-small': _vm.randomizedOptions.length > 6,
          'large-portrait': _vm.data.fields.largePortrait,
          'display-selection-order': _vm.data.fields.displaySelectionOrder,
          'display-text-options': _vm.data.fields.textOptions
        },style:({'backgroundImage': `url(${_vm.data.fields.textOptions && _vm.randomizedLabels[key] !== '...' && _vm.randomizedLabels[key].indexOf('...') === 0 ? '' : box.fields.file.url})`}),attrs:{"title":box.fields.title,"span":12},on:{"click":function($event){return _vm.toggleThis(box.fields.title, key)}}},[(!_vm.data.fields.displaySelectionOrder)?_c('i',{staticClass:"el-icon-check icon-inside"}):_vm._e(),(_vm.data.fields.displaySelectionOrder && _vm.getPeckingOrder(box.fields.title))?_c('div',{staticClass:"selection-orderx icon-inside"},[_vm._v(_vm._s(_vm.getPeckingOrder(box.fields.title)))]):_vm._e(),(_vm.data.fields.renderImageTitle && (_vm.randomizedLabels && _vm.randomizedLabels[key] && _vm.randomizedLabels[key].indexOf('...') !== 0) )?_c('span',{staticClass:"rendered-image-title"},[_vm._v("\n            "+_vm._s(_vm.randomizedLabels && _vm.randomizedLabels[key] ? _vm.getBoxLabel(_vm.randomizedLabels[key]) : box.fields.title)+"\n        ")]):_vm._e(),(_vm.randomizedLabels && _vm.randomizedLabels[key] && _vm.randomizedLabels[key].indexOf('...') === 0)?_c('span',{staticClass:"rendered-image-title large-label"},[_vm._v("\n            "+_vm._s(_vm.randomizedLabels && _vm.randomizedLabels[key] ? _vm.getBoxLabel(_vm.randomizedLabels[key]) : box.fields.title)+"\n        ")]):_vm._e()])}),0)]),_c('SkipQuestionButton',{attrs:{"align-left":_vm.validAnswers > 1,"disable-submit-button":!_vm.inputIsValid && _vm.validAnswers > 1,"show-submit":_vm.validAnswers > 1,"is-mandatory":_vm.data.fields.isMandatory},on:{"skip":function($event){return _vm.skipQuestion()},"submit":function($event){return _vm.submitQuestion()}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }