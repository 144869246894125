<template>
  <div class="full-area" :class="{'feedback': feedback}" v-if="randomizedOptions">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="clearAndGo()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle && randomizedOptions.length <= 4"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="content"
      :class="{
        'align-top': randomizedOptions.length >= 4,
        'three-rows': randomizedOptions.length > 4
      }"
    >
      <div class="grid-wrapper delay-entry">
        <div
          :title="box.fields.title"
          :span="12"
          v-for="(box, key) in randomizedOptions"
          :style="{'backgroundImage': `url(${data.fields.textOptions && randomizedLabels[key] !== '...' && randomizedLabels[key].indexOf('...') === 0 ? '' : box.fields.file.url})`}"
          :key="box.sys.id"
          class="binary-box"
          :class="{
            'standoutx': form.selected === box.fields.title || form.selected.includes(box.fields.title),
            'render-image-wrapper': data.fields.renderImageTitle,
            'xtra-small': randomizedOptions.length > 6,
            'large-portrait': data.fields.largePortrait,
            'display-selection-order': data.fields.displaySelectionOrder,
            'display-text-options': data.fields.textOptions
          }"
          @click="toggleThis(box.fields.title, key)"
        >
          <i class="el-icon-check icon-inside" v-if="!data.fields.displaySelectionOrder"></i>
          <div class="selection-orderx icon-inside" v-if="data.fields.displaySelectionOrder && getPeckingOrder(box.fields.title)">{{getPeckingOrder(box.fields.title)}}</div>
          <span
            class="rendered-image-title"
            v-if="data.fields.renderImageTitle && (randomizedLabels && randomizedLabels[key] && randomizedLabels[key].indexOf('...') !== 0) ">
              {{randomizedLabels && randomizedLabels[key] ? getBoxLabel(randomizedLabels[key]) : box.fields.title}}
          </span>
          <span
            class="rendered-image-title large-label"
            v-if="randomizedLabels && randomizedLabels[key] && randomizedLabels[key].indexOf('...') === 0">
              {{randomizedLabels && randomizedLabels[key] ? getBoxLabel(randomizedLabels[key]) : box.fields.title}}
          </span>
        </div>
      </div>
    </content>

    <SkipQuestionButton
      :align-left="validAnswers > 1"
      :disable-submit-button="!inputIsValid && validAnswers > 1"
      :show-submit="validAnswers > 1"
      :is-mandatory="data.fields.isMandatory"
      v-on:skip="skipQuestion()"
      v-on:submit="submitQuestion()"
    ></SkipQuestionButton>
  </div>
</template>

<script>
export default {
  name: 'Bilderset',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      randomizedFormula: [],
      randomizedOptions: [],
      randomizedLabels: [],
      randomizedSkip: '',
      form: {
        type: `bilderset`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        questionLength: this.data.fields.title.length,
        numberOfOptions: this.data.fields.optionsImages.length,
        optionsImages: this.data.fields.optionsImages,
        textOptions: this.data.fields.textOptions,
        id: this.data.sys.id,
        selected: [],
        labelSelection: [],
        splitSelected: {},
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    validAnswers () {
      if (this.data.fields.validAnswers <= this.data.fields.optionsImages.length) {
        return this.data.fields.validAnswers
      } else {
        return 1
      }
    },
    inputIsValid () {
      if (this.data.fields.displaySelectionOrder) {
        if (this.form.selected.length === this.validAnswers) {
          return true
        } else {
          return false
        }
      }
      if (this.form.selected.length >= 1 && this.form.selected.length <= this.validAnswers) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    getBoxLabel (label) {
      if (label.indexOf('...') === 0) {
        return label.substring(3)
      }
      return label
    },
    randomize () {
      if (this.data.fields.noRandomize) {
        this.randomizedOptions = this.data.fields.optionsImages
        this.randomizedFormula = this.data.fields.formula
        this.randomizedLabels = this.data.fields.textOptions
        this.randomizedSkip = this.data.fields.skip
        return
      }
      // Randomize images and formulas so that they go together
      const options = this.data.fields.optionsImages
      var temp = []
      for (var i = 0; i < this.data.fields.optionsImages.length; i++) {
        temp.push(i)
      }
      temp.sort(function () { return 0.5 - Math.random() })
      this.randomizedOptions = []
      this.randomizedFormula = []
      this.randomizedLabels = []
      var randomizedSkipArray = []

      if (this.data.fields.skip) {
        var explodedSkip = this.data.fields.skip.split(',')
      }

      temp.forEach(index => {
        this.randomizedOptions.push(this.data.fields.optionsImages[index])
        if (this.data.fields.formula) {
          this.randomizedFormula.push(this.data.fields.formula[index])
        }
        if (this.data.fields.textOptions) {
          this.randomizedLabels.push(this.data.fields.textOptions[index])
        }
        if (this.data.fields.skip) {
          randomizedSkipArray.push(explodedSkip[index])
        }
      })
      if (this.data.fields.skip) {
        randomizedSkipArray.push(explodedSkip[explodedSkip.length - 1])
        this.randomizedSkip = randomizedSkipArray.join(',')
      }
    },
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    clearAndGo () {
      this.feedback = ''
      this.emitNext()
    },
    emitNext () {
      if (!this.feedback) {
        this.$emit('next', this.form)
      }
    },
    skipQuestion () {
      this.form.selected = ['S99']
      this.next()
    },
    submitQuestion () {
      this.next()
    },
    toggleThis (item, key) {
      if (this.locked) {
        return
      }
      if (!this.form.selected.includes(item)) {
        if (this.form.selected.length < this.validAnswers) {
          this.form.selected.push(item)
          this.form.splitSelected[key] = 1
          if (this.randomizedLabels) {
            this.form.labelSelection.push(this.randomizedLabels[key])
          }
        }
      } else {
        this.form.selected = this.form.selected.filter(el => {
          this.form.splitSelected[key] = 0
          return el !== item
        })
        this.form.labelSelection = this.form.labelSelection.filter(el => {
          return el !== this.randomizedLabels[key]
        })
      }
    },
    getPeckingOrder (item) {
      var x = ''
      this.form.selected.forEach((el, key) => {
        if (item === el) {
          return x = key + 1
        }
      })
      return x
    },
    calculateScores () {
      this.locked = true
      return this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.randomizedFormula,
        selected: this.form.selected,
        options: this.randomizedOptions,
        maxAnswers: this.data.fields.validAnswers,
        skip: this.randomizedSkip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    },
    next () {
      if (this.inputIsValid || (this.data.fields.displaySelectionOrder && this.form.selected[0] === 'S99')) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      } else {
        return
      }
      this.form.timestamps.finish = new Date()
      if (this.$refs['player']) {
        this.$refs.player.player.pause()
      }
      this.calculateScores()
    }
  },

  mounted () {
    this.randomize()
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.inputIsValid && this.validAnswers === 1) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-inside.selection-orderx {
  bottom: unset;
  top: 5px;
}
.large-label {
  height: 94% !important;
  width: 94% !important;
  padding: 3% !important;
  font-size: 20px !important;
  line-height: 1.1 !important;
  display: flex !important;
  border-radius: 5px !important;
  border: none;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
  color: $text-main !important;
}
.display-text-options {
  border: 1px solid rgba($gray-medium, 0.2) !important;
}
.binary-box.standoutx.display-selection-order {
  transform: scale(1.02) !important;
}
.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;

  @include breakpoint($micro) {
    margin-bottom: 30px;
  }
  @include breakpoint($desktop) {
    align-items: flex-start;
    margin-bottom: 0;
  }
  &.align-top {
    @include breakpoint($desktop) {
      align-items: flex-start;
    }
  }
}

.binary-box {
  opacity: 0.9;
  transform: scale(1);
  transition: opacity ease-in-out 0.2s, transform 0.2s ease-in-out;
  position: relative;
  border: 1px solid $gray-light !important;

  &.standoutx {
    animation: standOut 0.4s ease-in-out;
    opacity: 1;
    transform: scale(1.05);
    z-index: 100;

    .icon-inside {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }
}
.content.three-rows {
  @include breakpointHeight($square) {
    transform: scale(0.8);
    max-height: 65vh;
  }
  .binary-box {

    @include breakpoint($micro) {
      width: 40%;
      padding-bottom: calc(40% * 3/4);
      margin-bottom: calc(calc(100% - (40% * 2)) / 6);
      margin-top: calc(calc(100% - (40% * 2)) / 6);
    }
    @include breakpointHeight($square) {
      width: 40%;
      padding-bottom: calc(40% * 3/4);
      margin-bottom: calc(calc(100% - (40% * 2)) / 16);
      margin-top: calc(calc(100% - (40% * 2)) / 16);
    }
  }
}
.icon-inside {
  opacity: 0.0001;
  transform: scale(0.9);
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  background: $color-emphasis-alt;
  border: 2px solid white;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-image-wrapper {
  border: 1px solid rgba($gray-medium, 0.4);
  background-position-y: top;
}

.rendered-image-title {
  z-index: -1;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 0.8em;
  background: rgba($color-admin-emphasis, 0.8);
  color: white;
}

</style>
